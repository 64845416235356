/* TopNav.css */

.toplinks .nav-link {
  margin: 0 20px;
  font-size: 18px;
  padding: 8px 20px;
  color: #ffffff;
  border-radius: 10px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.toplinks .nav-link:hover {
  color: rgb(255, 202, 44);
}

.toplinks .nav-link:focus {
  color: rgb(255, 202, 44);
}

.top-links-container {
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}

.top-bar-links {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.top-bar-links h6 {
   font-size: 1.1rem;
  font-weight: 600 !important;
}

.topNavNewStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(34, 34, 34, 0.8);
  box-shadow: -1px 0px 5px 7px rgba(214, 214, 214, 0.2);
  height: 60px;
  color: white;
}

.topNavNewStyle.yellow-pill {
  background-color: rgba(214, 214, 214, 0.5) !important;
}

.yellow-pill {
  background-color: rgb(255, 196, 0) !important;
  color: #ffffff !important;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.yellow-pill:focus,
.yellow-pill .nav-link:focus {
  background-color: rgb(255, 202, 44);
  color: white ;
}

.logo-wrapper {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  margin-left: 25px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.logo-wrapper:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.logo-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.user-profile-wrapper:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.user-profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* Responsive Styles */

/* Large screens (desktops) */
@media (min-width: 1200px) {
  .toplinks .nav-link {
    font-size: 20px;
    margin: 0 25px;
    padding: 10px 25px;
  }

  .topNavNewStyle {
    height: 70px;
  }

  .logo-wrapper {
    width: 50px;
    height: 50px;
  }
}

/* Medium screens (laptops) */
@media (max-width: 1199px) {
  .toplinks .nav-link {
    font-size: 18px;
    margin: 0 15px;
    padding: 8px 18px;
  }

  .logo-wrapper {
    width: 45px;
    height: 45px;
  }

  .topNavNewStyle {
    height: 65px;
  }
}

/* Tablets */
@media (max-width: 990px) {
  .toplinks .nav-link {
    font-size: 16px;
    margin: 0 10px;
    padding: 6px 15px;
  }

  .topNavNewStyle {
    padding: 10px 15px;
    height: 60px;
  }

  .logo-wrapper {
    width: 40px;
    height: 40px;
  }

  .toplinks {
    flex-direction: column;
    align-items: center;
  }

  .topNavNewStyle .navbar-collapse {
    text-align: center;
  }
}

/* Small screens (mobile phones) */
/* @media (max-width: 868px) {
  .toplinks .nav-link {
    font-size: 14px;
    margin: 5px 5px;
    padding: 8px 8px;
    width: 100%;
    text-align: center;
  }

  .topNavNewStyle {
    padding: 10px;
  }

  .logo-wrapper {
    margin: 10px auto 10px auto;
  }

  .topNavNewStyle .navbar-collapse {
    text-align: center;
  }

  .navbar-toggler {
    margin-left: auto;
  }
} */

/* Small screens (mobile phones) */
@media (max-width: 767px) {
  .toplinks {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .toplinks .nav-link {
    font-size: 14px;
    width: 100%;
    padding: 8px;
  }

  .topNavNewStyle {
    padding: 10px;
    height: auto;
  }

  .logo-wrapper {
    margin: 0 auto;
  }

  .navbar-toggler {
    margin-left: auto;
  }

  .navbar-collapse {
    text-align: center;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .navbar-toggler {
    width: 50px; 
    height: 50px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #000; 
    border-radius: 5px; 
  }

  .navbar-brand {
    margin-right: auto;
  }

  .navbar-toggler-icon {
    font-size: 18px; 
  }
}

