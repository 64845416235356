
.sidebar {
  background-color: #f8f9fa;
  padding: 15px;
  border-right: 1px solid #dee2e6;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-check-label {
  margin-left: 0.5rem;
}

.btn-register-custom {
  background-color: #7e3e4a !important;
  color: white !important;
  border: none;
}

@media (max-width: 767px) {
  .sidebar {
    min-height: auto;
    border-right: none;
  }
}

@media (max-width: 768px) {
  h3 {
    font-size: 1.5rem;
  }

  h6 {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  h3 {
    font-size: 1.3rem;
  }

  h6 {
    font-size: 1rem;
  }
}
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .driving-school-card {
    flex-direction: column;
    text-align: center;
  }

  .driving-school-card img {
    width: 100%;
    height: auto;
  }
}
