/* Global Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

/* Admin Home Container */
.admin-home {
  padding: 30px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* Stats Cards */
.stats-cards {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.card {
  flex: 1;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
}

.icon-container {
  background-color: #f4f4f4;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.icon {
  font-size: 24px;
  color: #ff6b6b;
}

.text-container p {
  font-size: 14px;
  color: #888;
  margin: 0;
  font-weight: bold;
}

.text-container h2 {
  font-size: 24px;
  color: #333;
  margin: 5px 0 0;
  text-align:center;
}

/* Custom Colors for Icon Backgrounds */
.card:nth-child(1) .icon-container {
  background-color: rgba(255, 107, 107, 0.2);
}

.card:nth-child(2) .icon-container {
  background-color: rgba(144, 238, 144, 0.2);
}

.card:nth-child(3) .icon-container {
  background-color: rgba(173, 216, 230, 0.2);
}

.card:nth-child(4) .icon-container {
  background-color: rgba(221, 160, 221, 0.2);
}

/* Main Info Section */
.main-info {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.school-info {
  display: flex;
  align-items: center;
  gap: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 100%;
}

.school-info .logo {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  border: 1px solid #eee;
}

.school-info-content {
  display: flex;
  flex-direction: column;
}

.school-info-content h3 {
  font-size: 20px;
  color: #1f1f1f;
  margin: 0;
  font-weight: bold;
  text-align:start;
}

.school-info-content .reg-no {
  background: #fdb863;
  padding: 4px 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  margin: 8px 0;
  width:115px;
}

.school-info-content p {
  color: #4f4f4f;
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

/* Additional Info Section */
.additional-info {
  display: flex;
  gap: 20px;
  width: 100%;
}

/* Recent Students Section */
.recent-students {
  flex: 1;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.recent-students h4 {
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom:15px;
}

.student-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.student-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  padding:10px
}

.student-initial {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
}

/* Student Gender Chart Section */
.student-gender-chart {
  flex: 1;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.student-gender-chart h4 {
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom:15px;
}

.gender-count {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  font-weight: bold;
  font-size: 16px;
}

.gender-count p:first-child {
  color: #FF4D4D;
}

.gender-count p:last-child {
  color: #FFD700;
}

/* Responsive Design */
@media (max-width: 768px) {
  .stats-cards, .main-info, .additional-info {
    flex-direction: column;
  }
}
