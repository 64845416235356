@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');


* {
  
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%; 
  overflow-x: hidden; 
  font-family: 'Nunito', sans-serif;
}

.App {
  max-width: 100%;
  margin: 0 auto; 
  padding: 0;
}

.container {
  max-width: 100%; 
}

@media (max-width: 768px) {
  .App {
    padding: 0 0px; 
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ------------------------------------------------------------------- */

@media (max-width: 575px) {
  .App {
    padding: 0 0px; 
  }

  .container {
    width: 100%;
    margin: 0 auto;
    padding: 0 5px; 
  }

  h5 {
    font-size: 16px; 
  }

  .btn {
    font-size: 14px; 
    padding: 8px 12px; 
  }

  img {
    max-width: 100%; 
    height: auto; 
  }

  
  .store-img {
    max-width: 50%; 
  }

  .storeLogImg {
    width: 150px; 
    height: auto;
  }
}
