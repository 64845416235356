
/* .body {
    background-image: url('/src/assets/images/Rectangle.png');
    background-position: -50px;
    background-size: 60%;
    background-repeat: no-repeat;
    z-index: -5;
} */

.show-topnav-top {
  z-Index: 1000
}

.links-div {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-content: space-between;
  font-size: calc(2vmin);
  
}

.links{
    color: white;
    padding: 10px;
    text-decoration: solid;
}
.mainTopImage{
    z-index: 2;
}