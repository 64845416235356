
/* SpotPaper.css */

.container {
  width: 100%;
}

.question-container {
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}




.show-answer-container {
  background-color: #f4f4f4; 
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  width: 90%; 
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);  
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
}

.correct-answer {
  background-color: #c8e6c9; 
}

.wrong-answer {
  background-color: #ffcdd2; 
}
.paper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.question {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px; 
  text-align: center;
  width: 100%;
}



.answer-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto; 
}

.answer-button:hover {
  background-color: #0056b3;
}

.answers-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  width: 100%;  
  margin: 0 auto;
}

.label-container {
  display: block;
  width: 100%; 
  margin: 10px 0;
  cursor: pointer;
  text-align: left; 
}
.answer-display{
  color: #007bff;
  height: 10px;
  background-color: #007bff;
}

input[type="checkbox"] {
  margin-right: 10px;  
}
.details-container {
  position: fixed; 
  right: 20px;     
  top: 40px;        
  width: 250px;     
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.timer {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}

.fixed-positions-questions {
  position: fixed;
}


.show-answer-under {
  clear: both;
  z-index: 100;
}
.topIndicatorStyle{
  margin: 20px 100px 0 100px;
  box-shadow: -1px 0px 5px 7px rgba(214, 214, 214, 0.8);
  border-radius: 25px;
  height: 60px;
}

.tabTopIndicatorStyle {
  margin: 20px 10px 0 10px; 
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
}

.pieChartLable{
  display: flex;
  justify-Content: center;
  margin-Top: 5px;
}

.topScoreCharts{
  border-radius: 25px;
}

.image-container {
    flex-shrink: 0;
}

.quection-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.question-text {
    flex: 1;
}
.spotPaperAnswersAlertMargine {
  
  margin-left: -35px !important; 
  padding: 8px; 
  display: inline-block;
}

.question-text-size {
    font-size: 1.25rem;
    font-weight: 600;
}

.answer-text-size {
    font-size: 1.2rem;
    font-weight: 500;
}

.image-style {
  width: 25%;
  max-width: 150px; 
  height: auto;
  margin: 10px auto; 
  display: block;
}

@media (max-width: 990px) {
  .question-text-size {
    font-size: 1.15rem;
    font-weight: 600;
}

.answer-text-size {
    font-size: 1rem;
    font-weight: 500;
}
}

@media (max-width: 768px) {
  .question-text-size {
    font-size: 1rem;
    font-weight: 600;
}

.answer-text-size {
    font-size: 0.9rem;
    font-weight: 500;
}
}

@media (max-width: 480px) {
  .question-text-size {
    font-size: 0.9rem;
    font-weight: 600;
}

.answer-text-size {
    font-size: 0.8rem;
    font-weight: 500;
}
}