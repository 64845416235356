.button-design {
    border-radius: 40px;
    border-width: 2px;
    border-color: #dfcdcd;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.15);
    padding: 10px;
    padding: 1vw;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.button-design:hover {
    transform: translateY(-5px) scale(1.03);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), 0 12px 30px rgba(0, 0, 0, 0.2);
    background-color: #71a9ec33;
    color: #ffffff;
}

.papers-card-text {
    font-size: clamp(0.9rem, 2vw, 1.2rem); 
    text-align: center;
}

.paper-button:active {
    transform: translateY(2px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

@media (max-width: 990px) {
    .button-design:hover {
        color: #0277ca;
    }
}


@media (max-width: 768px) {
  .button-design {
    border-width: 2px;
    padding: 0.8vw;
  }

  .papers-card-text {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .button-design {
    padding: 0.6vw;
    border-radius: 30px;
  }

  .papers-card-text {
    font-size: 0.9rem;
  }
}