.active-members-page {
  padding: 20px;
  background-color: #f9f9f9;
}

.active-members-container {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.active-members-container h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  border-bottom: 1px solid #ddd;
  text-align: start;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.left-filters {
  display: flex;
  gap: 10px;
  align-items: center;
}

.right-filters {
  display: flex;
  gap: 10px;
  align-items: center;
}


.search-input-wrapper,
.date-input-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.date-input {
  padding: 10px 40px 10px 12px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 0.9em;
  width: 160px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #333;
  outline: none;
  transition: border-color 0.2s ease;
}

.date-input:focus {
  border-color: #3b82f6;
}

.date-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #4b5563;
  font-size: 1.1em;
  pointer-events: none;
}

.search-input,
.date-input,
.dropdown {
  padding: 10px 12px;
  padding-right: 35px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 0.9em;
  width: 160px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #333;
  outline: none;
  transition: border-color 0.2s ease;
}

.search-input:focus,
.date-input:focus,
.dropdown:focus {
  border-color: #3b82f6;
}

.search-icon,
.date-icon {
  position: absolute;
  right: 12px;
  color: #4b5563;
  font-size: 1.1em;
  pointer-events: none;
}

.dropdown-wrapper {
  position: relative;
  display: inline-block;
  width: 160px;
}

.dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.dropdown-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6b7280;
  font-size: 1em;
  pointer-events: none;
}



.members-table {
  width: 100%;
  border-collapse: collapse;
}

.members-table th,
.members-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.members-table th {
  background-color: #f3f4f6;
  font-weight: bold;
  color: #333;
}

.members-table td {
  color: #555;
}

.action-icon {
  color: #ff6347;
  cursor: pointer;
  font-size: 1.2em;
  text-align: center;
}

.action-icon:hover {
  color: #e55347;
}
