.edit-profile-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.edit-profile-title {
  text-align: start;
  font-size: 24px;
  color: #333;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.profile-section-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.upload-photo-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #d9d9d9;
  border-radius: 8px;
  color: #ff4d4d;
  height: 200px;
  position: relative;
  text-align: center;
  font-size: 16px;
}

.upload-icon {
  font-size: 32px;
  color: #333;
  margin-bottom: 8px;
}

.profile-details-container {
  flex: 2;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.profile-details-container div {
  display: flex;
  flex-direction: column;
}

.input-label {
  font-size: 14px;
  color: #555;
}

.input-field, .text-area {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.text-area {
  resize: vertical;
  grid-column: span 2;
}

.edit-photo-container {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  grid-column: span 2;
  height: 40px;
  position: relative;
  text-align: center;
  font-size: 16px;
}

.edit-icon {
  font-size: 24px;
  color: #0778fa;
  margin-bottom: 8px;
  display: flex;
  justify-content: end;
}

/* Branches Section */

.edit-branch-card {
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.edit-branch-card:hover {
  transform: translateY(-5px);
}

.edit-branch-card img {
  object-fit: cover;
  height: 150px;
}

.edit-branch-card-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.edit-branch-card-text {
  color: #555;
  font-size: 0.9rem;
}

.edit-branch-badge {
  background: #fdb863;
  padding: 4px 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  margin: 8px 0;
  width:115px;
}

.fa-icon {
  color: #007bff;
}

.shadow-sm {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

/* Branches Section -----   END -------*/


.services-documents-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.selected-services-container,
.required-documents-container {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.selected-services-container h3,
.required-documents-container h3 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.service-item-container,
.document-item-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.service-item-container input[type="text"],
.service-price-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.checkbox-label {
  font-size: 14px;
  color: #333;
}


.branches-section-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr); /* Three columns layout */
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.branches-section-container h3 {
  grid-column: 1 / -1; /* Full width for the title */
  text-align: left;
  font-size: 20px;
  margin-bottom: 10px;
}

.branches-section-container div {
  display: flex;
  flex-direction: column;
}

.input-label {
  font-size: 14px;
  color: #555;
}

.dropdown, .input-field {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.has-offers-container {
  display: flex;
  align-items: center;
  grid-column: span 1; /* Controls positioning in the grid */
}

.save-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.save-button {
  padding: 5px 24px;
  background-color: #7d3d49;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #fcb762;
}
