.student-profile-page {
  padding: 20px;
  background-color: #f5f6f8;
  color: #333;
  font-family: Arial, sans-serif;
}

.student-profile-page h2 {
  text-align: start;
  font-size: 24px;
  color: #333;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.student-profile-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  align-items: start;
}

.profile-card {
  background-color: #f9fafc;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.profile-card h3 {
  font-size: 18px;
  margin: 10px 0;
  color: #333;
}

.profile-card p {
  font-size: 14px;
  color: #555;
  margin: 2px 0;
}

.registration {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #555;
}

.reg-number {
  background-color: #eef2f6;
  padding: 5px 12px;
  border-radius: 5px;
  font-weight: bold;
  color: #333;
}

.right-side-cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.account-info, .selected-services, .date-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.account-info h3, .selected-services h3 {
  color: #3a3a3a;
  font-size: 16px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-top: 10px;
}

.info-grid p {
  font-size: 14px;
  color: #555;
  margin: 4px 0;
}

.info-grid p strong {
  font-weight: 600;
  color: #333;
}

.selected-services h3 {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #3a3a3a;
}

.services-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 10px;
}

.services-list label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #3a3a3a;
  gap: 8px;
}

.edit-icon {
  color: #007bff;
  cursor: pointer;
  font-size: 16px;
  vertical-align: middle;
  margin-left: 5px;
}

.dates-section {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.date-card {
  background-color: #f9fafc;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.date-card h4 {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
  font-weight: 500;
}

.date-picker {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* .date-picker span {
  font-size: 14px;
  color: #333;
  padding: 5px;
  background-color: #eef2f6;
  border-radius: 5px;
  flex-grow: 1;
  text-align: center;
} */

.date-picker button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-picker button:hover {
  background-color: #0056b3;
}

.date-picker svg {
  margin-right: 5px;
}

.date-picker-with-update {
  font-size: 14px;
  color: #333;
  padding: 5px;
  background-color: #eef2f6;
  border: none;
  border-radius: 5px;
  flex-grow: 1;
  text-align: center;
}

