

.card-signup {
  width: 100%;
  max-width: 400px; 
  border-radius: 10px; 
}



input:focus {
  box-shadow: none !important;
  border-color: #007bff !important; 
}

.button-signup {
  background-color: #007bff;
  border: none;
}

.button-signup:hover {
  background-color: #0056b3; 
}

@media (max-width: 576px) {
  .card {
    padding: 20px;
    box-shadow: none;
  }
}

.btn-register-color {
  background-color: #FFF650!important;
  font-weight: bold !important;
}

.btn-register-color:hover {
  box-shadow: none !important; 
  border-color: #eade00 !important; 
  background-color: #5e5a0a!important;
}

.icon-position {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: #aaa;
}

.pl-2 {
  padding-left: 10px;
}