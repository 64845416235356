.home-page-hero {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../../assets/HomeSection/homeMain-image-01.png") no-repeat center
      center/cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 5vw 2vw;
  padding-top: 5rem;
  box-sizing: border-box;
}

.text-container {
  text-align: center;
  padding-top: 7.5rem; 
  padding-bottom: 1rem; 
}

.section-container {
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;
}

/* @media (max-width: 768px) {
  .home-page-hero {
    height: 500px; 
    background-size: cover; 
  }
}

@media (max-width: 480px) {
  .home-page-hero {
    height: 400px;
    background-size: cover;
  }
} */

.section-vertical-space {
  padding: 7.5rem 0;
}

.home-main-title-h1 {
  font-family: "Inter", sans-serif;
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.home-main-title-p {
  font-family: "Baloo 2", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 25px;
}


@media (max-width: 1600px) {
  .home-main-title-h1 {
    font-size: 3rem;
  }

  .home-main-title-p {
    font-size: 1.2rem;
  }

  .home-page-hero {
    height: 810px;
  }
}

@media (max-width: 1440px) {
  .home-main-title-h1 {
    font-size: 3rem;
  }

  .home-main-title-p {
    font-size: 1.2rem;
  }

  .home-page-hero {
    height: 720px;
  }
}

@media (max-width: 1024px) {
  .home-main-title-h1 {
    font-size: 3rem;
  }

  .home-main-title-p {
    font-size: 1.2rem;
  }

  .home-page-hero {
    height: 600px;
  }
}

@media (max-width: 768px) {
  .home-main-title-h1 {
    font-size: 2.5rem;
  }

  .home-main-title-p {
    font-size: 1rem;
  }

  .home-page-hero {
    height: 500px;
  }
}

@media (max-width: 480px) {

  .text-container {
  padding-top: 2rem; 
  padding-bottom: 1rem; 
}

  .home-main-title-h1 {
    font-size: 2rem;
  }

  .home-main-title-p {
    font-size: 0.9rem;
  }

  .home-page-hero {
    height: 400px;
  }

  button {
    width: 100%;
    padding: 10px 0;
  }
}

#grad {
  background-image: linear-gradient(
    to right,
    rgb(241, 165, 69),
    rgb(251, 203, 67)
  );
}

.startnowtxt-head {
  font-family: "Inter", sans-serif;
  font-size: clamp(1.8rem, 3vw, 3.5rem);
  font-weight: bold;
  text-align: start;
}

.startnowtxt {
  font-family: "Inter", sans-serif;
  font-size: clamp(0.9rem, 2vw, 1.2rem);
  line-height: 1.6;
  text-align: justify;
  font-weight: 300;
}

.hide-on-large {
  display: none !important;
}
.hide-on-small {
  display: block !important;
}

@media (max-width: 1200px) {
  .top-margine {
    margin-top: 5rem;
  }
}

@media (max-width: 992px) {
  .hide-on-large {
    display: block !important;
  }
  .hide-on-small {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .startnowtxt-head {
    font-size: 2rem;
    line-height: 1.4;
  }

  .startnowtxt {
    font-size: 0.9rem;
    line-height: 1.6;
  }
}

@media (max-width: 576px) {
  .startnowtxt-head {
    font-size: 1.5rem;
    line-height: 1.3;
  }

  .startnowtxt {
    font-size: 0.8rem;
    line-height: 1.5;
  }
}


/*   Articles Section Style  */

.articles-section {
  background-color: #97979733;
}

.section-title {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: #14202f;
}

.article-card {
  border: none;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.article-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.article-card .btn {
  text-align: left;
  display: block;
  background-color: #ffffff;
}

.article-image {
  height: 200px;
  object-fit: cover;
}

.card-title {
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #14202f;
  text-align: left;
}

.card-text {
  font-size: 0.8rem;
  font-weight: 300;
  color: #484645;
  text-align: justify;
}

.btn-articles {
  background-color: #fff !important;
  font-weight: 600;
}

/* get driving-school Section */

.get-driving-school-section {
  background-color: #1c274c;
  color: white;
}

/* Image  */
.get-driving-school-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Title */
.promo-title {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .promo-title {
    font-size: 1.8rem;
  }
}

/* Description */
.promo-description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

@media (max-width: 990px) {
  .promo-description {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .promo-description {
    font-size: 0.8rem;
  }
}

/* Button */
.promo-button {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.5rem 2rem;
  border: none;
  background-color: #ffc107;
  color: #ffffff !important;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease;
}

.promo-button:hover {
  background-color: #e0a800;
}

@media (max-width: 990px) {
  .promo-button {
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .promo-button {
    font-size: 0.9rem;
    padding: 0.5rem 1.5rem;
  }
}

.driving-form-section {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
}

.form-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: left;
}

.form-description {
  font-size: 1rem;
  color: #666;
  text-align: justify;
  margin-bottom: 2rem;
}

.form-subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: left;
}

.form-control {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .driving-form-section {
    padding: 1rem;
  }

  .form-title {
    font-size: 1.8rem;
    text-align: center;
  }

  .form-description {
    font-size: 0.9rem;
  }

  .form-subtitle {
    font-size: 1.3rem;
    text-align: center;
  }
}

.footer-section {
  background-color: #1c274c;
  color: white;
  padding: 50px 0;
}

.footer-title {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.footer-text {
  font-size: 0.9rem;
  line-height: 1.6;
}

.footer-bottom {
  font-size: 0.8rem;
  margin-top: 15px;
  opacity: 0.8;
}

.apps-click:hover {
  text-decoration: underline;
}

.subscribe-input {
  border-radius: 20px 0 0 20px;
  border: none;
  height: 50px;
  flex-grow: 1;
  padding: 0 15px;
}

.subscribe-button {
  width: 100px;
  height: 50px;
  border-radius: 0px 20px 20px 0px;
  background-color: #00aef0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #fff;
}

.subscribe-button:hover {
  background-color: #007bce !important;
}

@media (max-width: 1150px) {

  .footer-title {
    font-size: 1.2rem;
    text-align: center;
  }

  .subscribe-input {
    height: 40px;
  }

  .subscribe-button {
    height: 40px;
    width: 80px;
  }
}

@media (max-width: 768px) {
  .footer-title {
    font-size: 1.2rem;
  }

  .footer-text {
    font-size: 0.85rem;
  }

  .subscribe-button {
    width: 60px;
  }

  .social-icons a {
    font-size: 1.3rem;
  }
}

@media (max-width: 576px) {

  .footer-title {
    font-size: 1rem;
    text-align: center;
  }

  .subscribe-input {
    height: 30px;
  }

  .subscribe-button {
    height: 30px;
    width: 60px;
  }
}

/* Try our Apps */

.our-app-image {
  width: 100%;
  max-width: 150px;
  height: auto;
  border-radius: 20px;
}

.fs-1 {
  font-size: 2rem;
}
.fs-4 {
  font-size: 1.2rem;
}

.text-sm-center {
  text-align: center !important;
}
.text-sm-start {
  text-align: left !important;
}

@media (max-width: 1200px) {
  .fs-1 {
    font-size: 1.8rem;
  }
  .fs-4 {
    font-size: 1rem;
  }
}
@media (max-width: 992px) {
  .fs-1 {
    font-size: 1.5rem;
  }
  .fs-4 {
    font-size: 0.9rem;
  }
  .our-app-image {
    max-width: 140px;
  }
}
@media (max-width: 768px) {
  .fs-1 {
    font-size: 1.3rem;
  }
  .fs-4 {
    font-size: 0.8rem;
  }
  .our-app-image {
    max-width: 120px;
  }
  .ps-lg-5 {
    padding-left: 1.5rem !important;
  }
}
@media (max-width: 576px) {
  .fs-1 {
    font-size: 1.1rem;
  }
  .fs-4 {
    font-size: 0.7rem;
  }
  .our-app-image {
    max-width: 100px;
  }
  .ps-lg-5 {
    padding-left: 0.5rem !important;
  }
}

