.auth-page-hero {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/LearnersAuthReg/authpageIMG.png') no-repeat center center;
  background-size: cover; 
  width: 100vw; 
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  overflow: hidden; 
}

.login-txt-color {
  color: #FFF650;
}

.register-txt-color {
  color: #FFF650;
}

.text-link-color {
  color: #f1e500 !important;
}

.text-link-color:hover {
  color: #5e5a0a!important;
}

/* .auth-container {
  
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
  width: 100%;
  max-width: 400px;
}  */

.auth-card {
  width: 100%;
  max-width: 400px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.2); 
  border: none; 
}

/* Styling for social sign-in buttons */
button.btn-danger-red {
  background-color: #db4437; 
  color: white;
  font-weight: bold;
}

button.btn-primary-blue {
  background-color: #3b5998; 
  color: white;
  font-weight: bold;
}

button.btn-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

button.btn-link:hover {
  color: #0056b3;
}


input.form-control {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1rem;
}


@media (max-width: 576px) {
  .auth-card {
    padding: 20px;
    box-shadow: none;
  }
}


.btn-google-fb {
  width: 150px; /* Ensures both buttons have the same width */
}

.btn-google-fb-danger {
  background-color: #db4437; /* Google's official red */
}

.btn-google-fb-primary {
  background-color: #4267B2; /* Facebook's official blue */
}