
.card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; 
}

.driving-card {
  max-width: 100%; 
}

.card-title {
  font-size: 1.5rem;
}

.card-subtitle {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .card-title {
    font-size: 1.25rem;
  }

  .card-subtitle {
    font-size: 0.9rem;
  }

  .card-text {
    font-size: 0.9rem;
  }
}

.driving-school-card-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
}

.btn-warning:hover {
  background-color: #ec971f;
}

@media (max-width: 768px) {
  .btn-warning {
    width: 100%;
  }
}
