/* ---------------------------------------------------tab view--------------------------------------------- */
.custom-tabs .nav-link {
  color: #495057;
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  background-color: transparent;
}

.custom-tabs .nav-link.active {
  color: #ff6b6b;
  border-bottom: 2px solid #ff6b6b;
  font-weight: bold;
  background-color: transparent;
  margin:none;
}


/* ---------------------------------------------------members container section--------------------------------------------- */
.admin-home-card {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
}


/* ---------------------------------------------------search view--------------------------------------------- */
.search-bar {
  display: flex;
  justify-content: flex-end; /* Aligns search to the far right */
  padding: 10px 15px;
  margin-bottom: 15px;
  gap: 10px;
  width: 100%; /* Takes up the full page width */
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
}

.search-input-wrapper input {
  padding: 8px 40px 8px 15px;
  width: 250px;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  font-size: 16px;
  box-sizing: border-box;
  text-align: left;
  transition: all 0.3s ease;
}

.search-input-wrapper input:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

.search-input-wrapper input::placeholder {
  color: #6c757d;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  right: 15px;
  font-size: 16px;
  color: #6c757d;
  cursor: pointer;
}



/* ---------------------------------------------------member page details cards view--------------------------------------------- */
.member-card {
  display: flex;
  justify-content: space-between;
  align-items: start;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 15px;
  width: 100%;
}

.member-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #6c757d;
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin-right: 15px;
}


/* ---------------------------------------------------pending requests section--------------------------------------------- */

.pending-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
}

.pending-info h5 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.pending-info p {
  margin: 0;
  font-size: 14px;
  color: #6c757d;
}

.pending-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  flex-shrink: 0;
}


/* ---------------------------------------------------accepted requests section--------------------------------------------- */
.accept-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
}

.accept-info h5 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.accept-info p {
  margin: 0;
  font-size: 14px;
  color: #6c757d;
}

.accept-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  flex-shrink: 0;
}


/* ---------------------------------------------------add members section--------------------------------------------- */
.members-section {
  padding: 0px;
  margin-top: 0px;
}

.member-card-horizontal {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 15px;
  width: 100%;
}

.member-card-horizontal-head {
  display: flex;
  align-items: end;
  width: 100%;
  padding:5px 60px 5px 15px;
  background-color: #f2f2f2;
  margin-bottom:15px;
}

.member-avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #6c757d;
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
}

.member-info-horizontal {
  display: flex;
  gap: 20px;
  align-items: center;
  flex: 1;
  justify-content: start;
  margin-bottom:8px;
}

.member-info-item {
  flex: 1;
  min-width: 150px;
  text-align: left;
  justify-content: start;
}

.name-item h5 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-align: left;
  justify-content: start;
}

.member-info-item p {
  margin: 0;
  font-size: 14px;
  color: #6c757d;
  text-align: left;
  justify-content: start;
}

.member-actions {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.member-actions-head{
  flex-shrink: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #6c757d;
  text-align: left;
  justify-content: start;
  font-weight: bold;
}

.member-info-item-head {
  flex: 1;
  min-width: 150px;
  text-align: left;
  justify-content: start;
  display: flex;
}

.member-info-item-head p {
  flex-shrink: 0;
  margin: 0;
  font-size: 14px;
  color: #6c757d;
  text-align: left;
  justify-content: start;
  font-weight: bold;
}

.member-avatar-head{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
  font-size: 14px;
  font-weight: bold;
  margin-right: 35px;
  margin-left: 20px;
}


/* ---------------------------------------------------buttons--------------------------------------------- */
.btn-accept,
.btn-active,
.btn-request {
  background-color: #d4edda;
  color: #155724;
  padding: 5px 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-accept:hover,
.btn-active:hover,
.btn-request:hover {
  background-color: #c3e6cb;
  transform: scale(1.05);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.btn-accept:active,
.btn-active:active,
.btn-request:active {
  transform: scale(0.98);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.btn-reject,
.btn-remove {
  background-color: #f8d7da;
  color: #721c24;
  padding: 5px 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-reject:hover,
.btn-remove:hover {
  background-color: #f5c6cb;
  transform: scale(1.05);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.btn-reject:active,
.btn-remove:active {
  transform: scale(0.98);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
