.answersRadioButton {
  margin-right: 10px;
}
.imageSize {
  width: 150px;
  height: auto;
}
.details {
  height: 100%;
}

.CircularProgressbar-path {
  stroke: green;
}
.CircularProgressbar-trail {
  stroke: gray;
}
.CircularProgressbar-text {
  fill: yellow;
}
.CircularProgressbar-background {
  fill: green;
}

.Progressbar-text {
  color: green;
  font-size: 25px;
  font-weight: 500;
}

.fixed-positions-questions {
  position: fixed;
}

.show-answer-under {
  clear: both;
  z-index: 100;
}

.topIndicatorStyle {
  margin: 20px 100px 0 100px;
  box-shadow: -1px 0px 5px 7px rgba(214, 214, 214, 0.8);
  border-radius: 30px;
  height: auto;
}

.tabTopIndicatorStyle {
  margin: 20px 10px 0 10px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
}

.papers-description-text {
  text-align: start;
  margin: 1rem 5rem 2rem 5rem;
  font-size: 1rem;
}

@media (max-width: 992px) {
  .topIndicatorStyle {
    margin: 10px 20px 0 20px;
  }

  .tabTopIndicatorStyle {
    margin: 20px 5px 0 5px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
  }
}

@media (max-width: 768px) {
  .topIndicatorStyle {
    margin: 10px 15px 0 15px;
    box-shadow: none;
    border-radius: 10px;
  }

  .tabTopIndicatorStyle {
    margin: 20px 5px 0 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .papers-description-text {
    text-align: start;
    margin: 1rem 3rem 2rem 3rem;
    font-size: 0.9rem;
  }
}

.pieChartLable {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.topScoreCharts {
  border-radius: 25px;
}

@media (max-width: 768px) {
  .topScoreCharts {
    border-radius: 12px;
  }
}

.image-container {
  flex-shrink: 0;
}

.quection-area {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.question-text {
  flex: 1;
}
.paperAnswersAlertMargine {
  margin-left: -16px !important;
  padding: 8px;
  display: inline-block;
}
