.driving-school-hero {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/LearnersUIimages/HeroImage.jpeg') no-repeat center center/cover;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center; 
}

.driving-school-hero h1 {
  font-size: 48px;
  font-weight: bold;
}

.driving-school-hero p {
  font-size: 18px;
  max-width: 600px;
  margin: 0 auto; 
}

.driving-school-hero .btn {
  margin-top: 20px;
  padding: 10px 30px;
  background-color: #f0ad4e;
  color: white;
  border: none;
  border-radius: 5px;
}

.driving-school-hero .btn:hover {
  background-color: #ec971f;
}

@media (max-width: 768px) {
  .driving-school-hero h1 {
    font-size: 36px;
  }

  .driving-school-hero p {
    font-size: 16px;
  }
}

.driving-school-details {
  margin: 50px 0;
  text-align: center; 
}

.driving-school-details h1 {
  font-size: 36px;
  font-weight: bold;
}

.driving-school-details p {
  font-size: 18px;
}


.card {
  border-radius: 10px;
  overflow: hidden;
  text-align: center; 
}

.card img {
  height: 200px;
  object-fit: cover;
  width: 100%; 
}

.card-title {
  font-size: 18px;
  margin-top: 10px;
}


.prices-section h2 {
  margin-bottom: 30px;
}

.table {
  font-size: 18px;
  margin: auto;
  max-width: 500px;
  text-align: center; 
}

.table td {
  padding: 10px;
}

@media (max-width: 768px) {
  .card img {
    height: auto;
  }

  .prices-section {
    padding: 20px;
  }
}
.services-page {
  margin-top: 50px;
  text-align: center; 
}

.services-section h2, .prices-section h2 {
  font-size: 36px;
  font-weight: bold;
}

.services-section p {
  font-size: 18px;
  margin-bottom: 30px;
  margin: 0 auto; 
}

.prices-section h2 {
  margin-bottom: 30px;
}


.card {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

/* Branches Section */
.branches-section {
  padding: 0;
  margin: 0;
  background-color: #333; 
}

@media (max-width: 768px) {
  .branches-section {
    padding: 0; 
    margin: 0; 
  }

  .branches-section .container {
    padding-left: 0;
    padding-right: 0;
  }

  .branches-section .row {
    margin-left: 0;
    margin-right: 0;
  }

  .branches-section .col-md-4 {
    padding-left: 0;
    padding-right: 0;
  }
}