.skip-btn{
    color: blue;
    text-decoration: underline;
}

.modal-top{
    margin-top: 8rem !important;
}

.form-check-inline {
  margin-right: 15px;
}

.form-check-input {
  margin-right: 5px; 
}

.form-btn {
    background-color: #1B1C4C !important;
    display: flex;
    justify-content: start;
  }

@media (max-width: 768px) {
  .user-form-label {
    font-size: 0.9rem;
  }
  .form-control, .form-select {
    font-size: 0.9rem;
    padding: 0.4rem;
  }
  .form-btn {
    font-size: 0.9rem;
  }
}


