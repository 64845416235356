.topnav-auth {
  height: 60px;
  background-color: #f8f9fa;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.notification-wrapper {
  margin: 5px;
  cursor: pointer;
}

.notification-icon {
  font-size: 24px;
  color: #555;
  margin-right: 15px;
}

.profile-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-wrapper:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logout-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  color: #555;
  margin: 5px;
  padding: 5px;
  transition: color 0.3s ease;
}

.logout-wrapper:hover .logout-icon {
  color: #d33;
}

.logout-icon {
  font-size: 24px;
}

.notification-icon,
.logout-icon {
  font-size: 24px;
  color: #555;
  transition: transform 0.3s ease, color 0.3s ease;
}

.notification-wrapper:hover .notification-icon {
  color: #fcb762;
  transform: scale(1.2);
}

.logout-wrapper:hover .logout-icon {
  color: #d33;
  transform: scale(1.2);
}

.topnav-auth .navbar-brand h5 {
  font-weight: bold;
  color: #333;
  margin: 0;
}

