
.about-p {
  font-size: 1rem;
  text-align: justify;
}

@media (max-width: 991px) {
  .about-p {
    font-size: 0.9rem; 
  }

  .content-container {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .about-p {
    font-size: 0.8rem; 
  }

  h3 {
    font-size: 1.5rem; 
  }

  .btn {
    font-size: 0.9rem; 
  }
}