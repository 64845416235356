.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s ease;
  padding: 10px;
  overflow: hidden;
}

.sidebar.collapsed {
  width: 60px;
}

.nav {
  padding: 0;
  flex-grow: 1;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  color: #1c284d;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 16px;
  border-radius: 10px;
}

.nav-item:hover {
  background-color: #e0e0e0;
}

.nav-item.active {
  background-color: #fcb762;
  color: #1c284d;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  transform: scale(1.05);
}

.nav-item.active .fa {
  color: #0056b3;
}

.menu-text {
  transition: opacity 0.3s ease;
}

.sidebar.collapsed .menu-text {
  opacity: 0;
  pointer-events: none;
}

.toggle-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #1c284d;
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  justify-content: flex-start;
}

.toggle-btn:focus {
  outline: none;
}

.toggle-btn:hover {
  background-color: #e0e0e0;
  border-radius: 10px;
}
