

.card-signin {
  background-color: rgba(0, 0, 0, 0.5); 
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
}

.text-btn-color {
  color: #f1e500 !important;
}

.text-btn-color:hover {
  color: #5e5a0a!important;
}

input:focus {
  box-shadow: none !important; 
  border-color: #007bff !important; 
}

.button-signin {
  background-color: #f1e500;
  border: none;
}

.button-signin:hover {
  background-color: #0056b3; 
}

@media (max-width: 576px) {
  .card {
    padding: 20px;
    box-shadow: none;
  }
}

.btn-login-color {
  background-color: #FFF650!important;
  font-weight: bold !important;
}

.btn-login-color:hover {
  box-shadow: none !important; 
  border-color: #eade00 !important; 
  background-color: #5e5a0a !important;
}
/* .icon-input {
  background: url('../../assets/AuthIcons/mail.png') no-repeat scroll 7px 7px;
  background-size: 20px 20px; 
  padding-left: 40px; 
  height: 40px;
  justify-content: center;
  align-items: center;
} */

.icon-position {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: #aaa;
}

.pl-2 {
  padding-left: 10px;
}
