.show-topnav-under-papers {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1050; 
}

.papers-content {
  margin-top: 70px; 
}

.papers-card {
  background: white;
  border: 2px solid #ffc107; 
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2); 
  transform: translateY(0);
}

.papers-card:hover {
  transform: translateY(-5px); 
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.3); 
  background-color: #ffb108;
  color: #ffffff;
}

.papers-card:active {
  transform: translateY(2px); 
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}