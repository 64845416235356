/* Modal Container */
.user-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

/* Modal Content */
.user-modal-content {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  width: 700px;
  max-width: 90%;
}

/* Welcome Section */
.user-welcome-section {
  background-color: #f3f4ff;
  color: #5b55f7;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  text-align: center;
}

.user-welcome-section h1 {
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
}

.welcome-modal-student-text {
  color: #392fb3;
}

.welcome-modal-get-started-section {
  background: linear-gradient(135deg, #4338ca, #5b55f7);
  color: white;
  padding: 2rem;
  width: 60%;
}

.welcome-modal-get-started-section h2 {
  margin-bottom: 1rem;
}

.welcome-modal-get-started-section ul {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.welcome-modal-get-started-section li {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  text-align: left;
  display: flex;    
  align-items: center;
}

.welcome-modal-login-link {
  color: #ffde59;
  text-decoration: none;
  font-weight: bold;
}

.welcome-modal-login-link:hover {
  text-decoration: underline;
}

/* Continue Button */
.welcome-modal-continue-btn {
  background-color: #ffde59;
  border: none;
  color: #333;
  font-weight: bold;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  border-radius: 5px;
  margin-top: 1rem;
  cursor: pointer;
}

.welcome-modal-continue-btn:hover {
  background-color: #f5d347;
}
