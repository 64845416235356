/* html, body {
  height: 100%;
  margin: 0;
  background-color: #d9d9d9;
} */

.admin-panel-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.admin-panel {
  display: flex;
  height: 100%;
}

.sidebar {
  width: 250px;
  transition: width 0.3s ease;
}

.admin-panel.collapsed .sidebar {
  width: 60px;
}

.content {
  flex: 1;
  padding: 20px;
  background-color: inherit;
  transition: margin-left 0.3s ease;
  margin-left: 250px;
  overflow-y: auto;
}

.admin-panel.collapsed .content {
  margin-left: 60px;
}
